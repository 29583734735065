
import {Component, Vue} from 'vue-property-decorator';
import {services} from '@/main';
import {IVisioCredentialsDto} from '@/services/dto/IVisioCredentialsDto';
import AlertService from '@/utils/AlertService';
import ErrorUtils from '@/utils/ErrorUtils';
@Component({})
export default class VisioCredentials extends Vue {
	// flag pour afficher le message si le token est incorrect
	private isWrongToken: boolean = false;
	// flag pour afficher le message si le token est correct
	private isRightToken: boolean = false;
	// attribut contenant le retour du service rest
	private retourCredentials: IVisioCredentialsDto = {} as IVisioCredentialsDto;
	// pour contenir l'url vers l'annuaire
	private annuaireUrl: string = '';
	/**
	 * hook qui est appelle avant de rendre la page
	 */
	private beforeMount(): void {
		const buildPage = (response: any) => {
			this.isRightToken = true;
			this.retourCredentials = response.data;
		};
		this.annuaireUrl = services.config.config.$directoy_visio_url;
		// appel REST pour savoir si le token est bon
		services.restService
		.callGet('/api/v1/office/current/services/visio/credentials?token=' + this.$route.query.token
		+ '&codecId=' + this.$route.query.codecId)
		.then(buildPage)
		.catch( (resp) => {
            if (resp !== undefined && resp.status === 403) {
            	this.isWrongToken = true;
            } else {
            	AlertService.error(ErrorUtils.msgDefault);
            	this.isWrongToken = false;
            }
		});
	}

}
